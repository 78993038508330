// https://vuex.vuejs.org/en/getters.html

export default {

  displayNameUsuario: (state) => {
    if (!state.usuario){
      return '-'
    }
    if (!state.usuario.first_name && !state.usuario.last_name){
      return state.usuario.username || state.usuario.email
    }

    return `${state.usuario.first_name} ${state.usuario.last_name}`
  },
}
